body {
    font-family: 'Montserrat', sans-serif;
}

.payment-content {
    padding: 25px;
    margin: auto;
    margin-top: 15px;
    margin-bottom: 50px;

    max-width: 500px;
}

.payment-header {
    font-size: 30px;
    font-weight: bolder;
    margin-bottom: 15px;
}

.payment-body {
    font-size: 16px;
    font-weight: light;
    margin-bottom: 20px;
}