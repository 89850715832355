body {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
  }
  
.showcase-page {
    display: flex;
    flex-direction: column;
}

.showcase-intro {
    text-align: center;
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
    max-width: 500px;
    margin-bottom: 0px;
    margin: auto;
  }
  
  .showcase-intro h1 {
    font-size: 36px;
    margin-bottom: 10px;
  }
  
  .showcase-intro p {
    font-size: 20px;
    color: #555;
  }
  
  .brand-questions {
    padding: 25px;
    margin: auto;
    max-width: 500px;
  }
  
  .brand-questions label {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 10px;
    display: block;
  }
  
  .text-area-showcase {
    font-family: inherit;
    font-size: 20px;
    height: 30px;
    padding: 10px 15px;
    border-radius: 5px;
    vertical-align: middle;
    resize: none;
    outline: none;
    border: 1px solid #ccc;
    min-height: 200px;
    width: calc(100% - 40px);
    margin-bottom: 30px;
    background-color: #f9f9f9; 
    color: #333;
  }
  
  .brand-kit-showcase {
    padding: 20px;
  }
  
  .brand-kit-item {
    text-align: center;
    max-width: 1000px;
    margin: auto;
    margin-bottom: 100px;
  }
  
  .brand-kit-item img {
    margin: auto;
    width: 100%;
    height: auto;
    border-radius: 10px;

    box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.1);
  }
  
  .brand-kit-item p {
    font-size: 18px;
    color: #444;
    margin-bottom: 25px;
    text-align: center;
  }

  .final-call-to-action {
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
    margin: auto;
    max-width: 500px;
    margin-bottom: 150px;
  }
  
  .final-call-to-action h2 {
    font-size: 26px;
    margin-bottom: 10px;
  }
  
  .final-call-to-action p {
    font-size: 20px;
    color: #555;
    margin-bottom: 20px;
  }

  .cta-button {
    padding: 15px 30px;
    font-size: 20px;
    font-weight: bold;
    font-family: inherit;
    color: #fff;
    background-color: #001f3f;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .cta-button:hover {
    font-family: inherit;
    background-color: black;
  }
  