/* src/Quote.css */
.quote-container {
    height: calc(100vh - 76px);
    z-index: -1;
    font-size: 20px;
    position: absolute;
    float: right;
    margin: 0px;
    overflow: hidden;
    cursor: pointer;
    color: #f0f0f0;
    text-align: center;
  }
  
  .quote-container:hover {
    color: #000;
  }
  