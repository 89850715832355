body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

.brand-kit-page {
  display: flex;
  flex-direction: column;
}

.kit-header {
  padding-left: 50px;
  padding-right: 50px;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 20px;
  text-align: center;
  border-bottom: 1px solid #eee;

}

.kit-header h1 {
  font-size: 40px;
  margin: 0;
}

.kit-header p {
  font-size: 20px;
  margin-top: 5px;
}

.kit-image {
  margin: auto;
  padding-left: 50px;
  padding-right: 50px;
  max-width: 500px;
  margin-bottom: 20px;
  border-radius: 15px;
}

.kit-logos {
  margin-left: 50px;
  margin-right: 50px;
}

.kit-logo {
  display: inline-block;
  max-height: 150px;
  margin: 10px;
  border-radius: 5px;
}

.kit-footer {
  margin: 50px;
  margin-top: 20px;
  margin-bottom: 100px;
  text-align: center;
}

.kit-footer h1 {
  font-size: 40px;
  margin: 0;
}

.kit-footer p {
  font-size: 20px;
  margin-top: 5px;
}


.download-section,
.moving-forward-section {
  padding-bottom: 50px;
  background-color: #fff;
  margin: auto;
  margin-bottom: 50px;
  width: 100%;
  border-bottom: 1px solid #eee;
  text-align: center;
}

.download-section h2,
.moving-forward-section h2 {
  margin-left: 50px;
  margin-right: 50px;
  font-size: 2rem;
  color: #001f3f;
  margin-bottom: 15px;
}

.download-section p,
.moving-forward-section p {
  margin-left: 50px;
  margin-right: 50px;
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 25px;
}

.download-button,
.contact-button {
  padding: 15px 30px;
  font-size: 20px;
  font-weight: bold;
  font-family: inherit;
  color: #fff;
  background-color: #001f3f;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.download-button:hover,
.contact-button:hover {
  font-family: inherit;
  background-color: black;
}

.star-rating {
  margin-bottom: 10px;
}

.review-text-area {
  font-size: 20px;
  font-family: inherit;
  height: 30px;
  border-radius: 5px;
  vertical-align: middle;
  resize: none;
  outline: none;
  border: 1px solid #ccc;

  min-height: 200px;
  margin: auto;
  padding-left: 50px;
  padding-right: 50px;
  max-width: 500px;
  margin-bottom: 30px;
}

  .spinner {
    margin: 25px;
    border: 6px solid #f3f3f3; /* Light grey */
    border-top: 6px solid black; /* Blue */
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: spin 2s linear infinite;
  }

  .animation-frame {
    max-width: 400px; 
    max-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: black; 
    margin: auto;
  
    box-shadow:
      0 0 0 10px #000, 
      0 0 0 20px white, 
      0 0 0 21px #000, 
      0 0 0 31px white, 
      0 0 0 32px #000,
      0 0 0 42px white, 
      0 0 0 43px #000;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  @keyframes fadeInOut {
    0%, 100% { opacity: 0; }
    50% { opacity: 1; }
  }
  
  