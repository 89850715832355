body {
    font-family: 'Montserrat', sans-serif;
}

.user-content {
    padding: 25px;
    margin: auto;
    margin-top: 15px;

    margin-top: 15px;

    max-width: 500px;
}

.cost-header {
    font-size: 20px;
    font-weight: bolder;
    margin-bottom: 10px;
    color: #006100af
}

.questions-header {
    font-size: 30px;
    font-weight: bolder;
    margin-bottom: 15px;
}

.questions-body {
    font-size: 16px;
    font-weight: light;
    margin-bottom: 20px;
}

.kitchen-img img {
    margin-bottom: 25px;
    width: 100%;
}

.text-area-header {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
}

.text-box {
    font-size: 20px;
    height: 30px;
    padding: 10px 15px;
    border-radius: 5px;
    vertical-align: middle;
    margin-right: 5px;
    border: 1px solid #ccc;
    outline: none;
    width: calc(100% - 40px);

    margin-bottom: 30px;
}

.promo {
    width: 100%;
    display: inline-block;
    margin-bottom: 10px;
}

.text-box-promo {
    display: inline-block;
    font-size: 20px;
    height: 30px;
    padding: 10px 15px;
    border-radius: 5px;
    vertical-align: middle;
    margin-right: 5px;
    border: 1px solid #ccc;
    outline: none;
    width: calc(100% - 125px);
}

.button-promo {
    background-color: white;
    border-radius: 5px;
    font-family: inherit;
    font-size: 16px;
    font-weight: bold;
    border: 1px solid #ccc;
    height: 52px;
    display: inline-block;
    vertical-align: middle;
    width: 80px;
}

.promo-code {
    cursor: pointer;
    padding: 10px 15px;
    text-align: center;
    background-color: #e3ffdf;
    border-radius: 5px;
    font-family: inherit;
    font-size: 16px;
    font-weight: lighter;
    color: #333;
    border: 1px solid #eee;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 20px;
}

.cancel-promo {
    font-weight: lighter;
    font-size: 20px;
    margin-left: 10px;
}

.text-area {
    font-size: 20px;
    height: 30px;
    padding: 10px 15px;
    border-radius: 5px;
    vertical-align: middle;
    resize: none;
    outline: none;
    border: 1px solid #ccc;

    min-height: 200px;

    width: calc(100% - 40px);

    margin-bottom: 30px;
}

.terms {
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: light;
}

.continue-button {
    padding: 15px 25px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    font-family: inherit;
    transition: background-color 0.3s;

    margin-bottom: 50px;
}

.continue-button:hover {
    background-color: #0056b3;
}