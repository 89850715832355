html, body {
    height: 100%;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
}

#root {
    height: 100%; /* Ensure the root container spans 100% */
}

.preview-container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.preview {
    flex-grow: 1; /* This ensures the preview object takes up the remaining height */
    width: 100%;
    height: calc(100% - 76px);
}
