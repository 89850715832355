.footer {
    /* background-color: white; */
    padding: 25px;
    padding-top: 25px;
    padding-bottom: 25px;
    text-align: center;

    box-shadow: 0px -5px 5px -3px rgba(0, 0, 0, 0.1);

    background-color: #001f3f;
    color: #fff;
}

.subscribe {
    margin-bottom: 25px;
}

.subscribe-header {
    font-weight: bolder;
    font-size: 20px;
    margin-bottom: 15px;
}

.email-input {
    display: inline-block;
    font-size: 16px;
    height: 30px;
    padding: 10px 15px;
    border-radius: 25px;
    vertical-align: middle;
    margin-right: 5px;

    max-width: calc(min(300px, 100% - 175px));
}

.input-block {
    display: inline-block;
    width: 100%;
}

.send-button {
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    width: 75px;
    height: 50px;
    background-color: white;
    border-color: black;
    border-radius: 25px;
    border-width: 1px;
}

.send-button-copy {
    vertical-align: middle;
    color: black;
    font: inherit;
    height: 50px;
    line-height: 50px;
    font-weight: bolder;
    font-size: 16px;
}

.send-button-loading  {
    margin-top: 9px;
    display: inline-block;
    vertical-align: middle;
    height: 25px;
    width: 25px;
    border: 3px solid #f3f3f3; /* Light grey */
    border-top: 3px solid black; /* Blue */
    border-radius: 50%;
    animation: spin 2s linear infinite;
    line-height: 50px;
  }

.instagram-logo {
    text-align: center;
    height: 30px;
}