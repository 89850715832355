body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App-logo {
  height: 150px;
  margin-top: 50px;
}

.App-logo img {
  width: 100%;
  height: 100%;
}

.App-header {
  margin-top: 0;
  margin-bottom: 50px;
  text-align: center;
  background-color: #ffffff;
  border-radius: 10px;
}

.App-body {
  margin: 50px;
}

h1 {
  font-size: 3em;
  margin: 20px 0;
}

.Mission-statement, .Description {
  font-size: 1.2em;
  margin: 20px 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  margin: 10px 0;
}

.Contact-sales-button {
  background-color: #007bff;
  color: white;
  padding: 15px 30px;
  font-size: 1em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s;
}

.Contact-sales-button:hover {
  background-color: #0056b3;
}

.Features-list {
  list-style-type: disc;
  padding-left: 20px;
  text-align: left;
}

textarea {
  font-family: inherit;
}

input {
  font-family: inherit;
}

p {
  font-family: inherit;
}


