body {
    font-family: 'Montserrat', sans-serif;
}

.nav {
    background-color: aqua;
    margin: 0;
    padding-left: 15px;
    padding-bottom: 1px;
    background-color: white;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.1);
}

.nav-logo {
    vertical-align:middle;
    height: 50px;
    margin-bottom: 15px;
    margin-top: 10px;
    margin-right: 8px;
}

.nav-copy {
    font-size: 25px;
    font-weight: bold;
}