.main {
  margin: 0;
  height: calc(100vh - 76px);
}

.main-container {
  z-index: 950;
  display: inline-block;
}

.get-started {
  position: absolute;
  bottom: 20px;
  right: 20px;

  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font-size: 30px;
  font-family: inherit;
  font-weight: 500;
  color: rgb(76, 68, 68); /* Sexy pink */
  cursor: pointer;
  /* text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); */
  transition: all 0.3s ease;
  outline: none;
}

.get-started:hover {
  font-weight: 550;
  color: black; /* Lighter sexy pink on hover */
}

.image {
  display: inline-block;
  width: 100px;
  margin: 50px;
  margin-right: 20px;
}

.image img {
  width: 100%;
  height: 100%;
}

.title {
  display: inline-block;
  width: 70%;
  font-size: 50px;
  margin: 50px;
  margin-top: 100px;
  margin-bottom: 300px;
}

.about {
  background-color: rgb(53,59,58);
  width: 100%;
}

.about-top {
  display: inline-block;
}

.about-top .logo img {
  width: 100px;
  margin: 50px;
}

.about-top .header {
  margin-top: 50px;
  color: white;
  font-size: 25px;
  font-weight: bolder;
  margin-bottom: 15px;
  width: calc(100vw - 250px);
}

.about-top .text {
  color: white;
  font-size: 20px;
  font-weight: lighter;
  width: calc(100vw - 250px);
  margin-bottom: 50px;
}

.copy, .logo {
  display: inline-block;
  vertical-align: middle;
}

.brand {
  margin: auto;
  text-align: center;
}

.brand img {
  width: min(100% - 50px, 350px);
  margin: 20px;
}

.brand-copy {
  margin: 50px;
  margin-top: 20px
}

.individuals img {
  width: 200px;
  margin: 20px;
}